<template>
  <div>
    <headerer></headerer>
    <div class="backserve">
      <img src="https://oss.001ke.com/website-m/platform/help/画板 8.png" alt="topbg" class="topbg" />
      <p class="title">全链路“管家式”服务</p>
      <div class="backservebox">
        <p class="title2">商业化投放</p>
        <p class="common">
          根据账号发展阶段科学投放流量，以“自然流量+商业流量”的方式，帮助达人突破GMV瓶颈，给予达人体验更优、转化效果稳定、可留存、可重访的优质流量。
        </p>
        <img src="https://oss.001ke.com/website-m/platform/help/1.png" alt="">

        <p class="title2">优质达人陪跑</p>
        <p class="common">
          平台累积入驻达人3万＋，易知课堂团队牵手头部达人，形成知识付费矩阵联盟，帮助新账号/0基础小白快速度过孵化期限，实现变现。
        </p>
        <img src="https://oss.001ke.com/website-m/platform/help/2.png" alt="">
      </div>

      <p class="title">售后“保姆级”服务</p>
      <div class="backservebox">
        <p class="title2">课程物料，免费包装</p>
        <p class="common">
          根据课程内容定位/目标学员群体等，提炼课程价值，设计完备的课程封面详情页，塑造价值感，专业感，促进用户做出购买决策，提高购买转化率。
        </p>
        <img src="https://oss.001ke.com/website-m/platform/help/3.png" alt="">

        <p class="title2">您放心拓客，免后顾之忧</p>
        <p class="common">收益直接提现至老师的账户，无需手续费。支持开发票。</p>
        <img src="https://oss.001ke.com/website-m/platform/help/4.png" alt="">
      </div>

      <p class="title">风控“保镖级”服务</p>
      <div class="backservebox">
        <p class="title2">易知课堂钱包服务</p>
        <p class="common">
          根据课程内容定位/目标学员群体等，提炼课程价值，设计完备的课程封面详情页，塑造价值感，专业感，促进用户做出购买决策，提高购买转化率。
        </p>
        <img src="https://oss.001ke.com/website-m/platform/help/5.png" alt="">

        <p class="title2">课程版权防盗保护</p>
        <p class="common">加密、防盗走马灯防录屏，全方面维护达人的知识产权。</p>
        <img src="https://oss.001ke.com/website-m/platform/help/6.png" alt="">
      </div>
    </div>
    <footerer></footerer>
  </div>
</template>

<script>
import headerer from "../headerer.vue";
import footerer from "../footerer.vue";
export default {
  components: {
    headerer,
    footerer,
  },
};
</script>

<style lang="less" scoped>
.backserve {
  width: 100vw;
  display: flex;
  flex-direction: column;
  animation: renderpage 1s cubic-bezier(0, 0.68, 1, 0.64) forwards;
  .backservebox {
    padding: 10px;
    img{
        width: 100%;
        object-fit: contain;
    }
  }
}

.topbg {
  width: 100%;
  object-fit: contain;
}

.title {
  font-weight: 600;
  margin: 20px 0;
  font-size: 18px;
  color: #000;
  text-align: center;
}
.title2 {
  font-weight: 600;
  font-size: 15px;
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title2::after {
  content: "";
  display: block;
  width: 45px;
  height: 3px;
  margin-top: 10px;
  background: rgb(25 94 215);
  border-radius: 10px;
}

.common {
  margin-top: 10px;
  font-size: 12px;
  text-align: center;
}

@keyframes renderpage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
