<template>
  <div class="ourpower">
    <p class="title">品牌实力</p>
    <p class="titleafter">强大可靠的研发能力，客户至上的服务理念</p>

    <div class="powerbox">
      <div
        class="poweritem"
        v-for="(item, index) in ourpowerdata[index]"
        :key="index"
      >
        <div class="title">
          <p class="num">{{item.num}}</p>
          <p class="titlep">{{item.titlep}}</p>
        </div>
        <p class="powerinfo">{{item.powerinfo}}</p>
      </div>
    </div>

    <div class="btnbox">
      <div class="btn" @click="index=0">平台资质</div>
      <div class="btn" @click="index=1">前沿科技</div>
      <div class="btn" @click="index=2">商业统筹</div>
    </div>
  </div>
</template>

<script>
import { ourpowerdata } from "./data/ourpower.js";
export default {
  data() {
    return {
      ourpowerdata,
      index:0,
    };
  },
};
</script>

<style lang="less" scoped>
.ourpower {
  width: 100%;
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 10px;
  .powerbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: rgb(240 244 255);
    margin-top: 32px;
    .poweritem {
      width: 100%;
      margin-top: 10px;
      margin-bottom: 20px;
      .title {
        width: 100%;
        font-size: 16px;
        display: flex;
        justify-content: center;
        .num {
          color: rgba(20, 72, 255, 0.8);
          margin-right: 5px;
        }
        .titlep {
          color: #817f7fce;
        }
      }
      .powerinfo {
        font-size: 12px;
        color: #000;
        text-align: center;
        margin-top: 10px;
      }
    }
  }

  .poweritem:nth-child(4) {
    margin-bottom: 10px;
  }

  .btnbox {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    .btn {
      width: 29%;
      height: calc(100vh * 0.049);
      font-size: 12px;
      color: #000;
      background-color: rgb(240 244 255);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
    }
  }
}

.title {
  width: 100%;
  text-align: rgb(20 72 255);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}
.titleafter {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
  margin-top: 8px;
}
</style>
