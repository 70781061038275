<template>
  <div class="topbg">
    <img
      class="bg"
      src="https://oss.001ke.com/website-m/home/topbg.png"
      alt="bg"
    />
    <div class="tagshow">
      <div class="item">
        <img
          class="tag"
          style="transform: scale(0.9)"
          src="https://oss.001ke.com/website-m/home/tag2.png"
          alt="tag"
        />
        <div class="iteminfo">
          <p class="count">{{ usernum }} 万+</p>
          <p class="info">小程序用户量</p>
        </div>
      </div>

      <div class="item">
        <img
          style="transform: scale(0.9)"
          class="tag"
          src="https://oss.001ke.com/website-m/home/tag1.png"
          alt="tag"
        />
        <div class="iteminfo">
          <p class="count">{{ teachernum }}+</p>
          <p class="info">入驻达人</p>
        </div>
      </div>

      <div class="item">
        <img
          style="transform: scale(0.9)"
          class="tag"
          src="https://oss.001ke.com/website-m/home/tag3.png"
          alt="tag"
        />
        <div class="iteminfo">
          <p class="count">{{ productnum }}+</p>
          <p class="info">知识商品</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
export default {
  data() {
    return {
      teachernum: 62288,
      usernum: 31521,
      productnum: 41421,
      index: 0,
      delay: 0,
    }
  },
  mounted() {
    requestAnimationFrame(this.addnum);
  },
  methods: {
    addnum() {
      if (this.index < 80) {
        this.teachernum += 2
        this.usernum += 2
        this.productnum += 2
        this.index++;
        this.delay++
        requestAnimationFrame(this.addnum);
      } else if (this.index < 200 && this.index > 80) {
        if (this.delay % 3 == 0) {
          this.teachernum += 2
          this.usernum += 2
          this.productnum += 2
          this.index++;
          this.delay++
          requestAnimationFrame(this.addnum);
        } else {
          this.index++;
          this.delay++
          requestAnimationFrame(this.addnum);
        }
      } else if (this.index > 200) {
        return
      } else {
        this.teachernum += 1
        this.usernum += 1
        this.productnum += 1
        this.index++;
        this.delay++;
        requestAnimationFrame(this.addnum);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.topbg {
  width: calc(100vw - 20px);
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  background: rgb(243 246 255);

  border-radius: 8px;

  .bg {
    width: 100%;
    object-fit: contain;
  }

  .tagshow {
    width: 100%;
    padding: 10px 0;
    display: flex;
    padding-right: 5px;
    justify-content: space-around;

    .item {
      display: flex;

      .tag {
        width: 42px;
        height: 42px;
      }

      .iteminfo {
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        .count {
          font-size: 12px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #010000;
          font-weight: 700;
        }

        .info {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 600;
          color: rgba(35, 123, 255, 0.8);
        }
      }
    }
  }
}
</style>
