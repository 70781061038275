<template>
  <div class="serveshow">
    <div class="title">以用户为中心的陪伴式服务</div>
    <p class="titleafter">增值生态服务，为达人提供全方位服务</p>

    <div class="swipercontent">
      <van-swipe
        :show-indicators="false"
        class="myswipe"
        :autoplay="3000"
        indicator-color="white"
      >
        <van-swipe-item class="myswiperitem">
          <div class="servetag">
            <img
              class="tagimg"
              src="https://oss.001ke.com/website-m/home/icon/3.png"
              alt="servetag"
            />
            <div class="sertaginfo">
              <p class="sertitle">稳定安全</p>
              <p class="sertitleafter">
                通过浏览器防录屏、播放域名防盗链等多种技术加密保护创作者的内容版权
              </p>
            </div>
          </div>

          <div class="serveinfo">
            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon1.png"
                alt="icon"
              />
              <p class="tagp">高清稳定流畅</p>
            </div>

            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (1).png"
                alt="icon"
              />
              <p class="tagp">更新迭代快</p>
            </div>

            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (2).png"
                alt="icon"
              />
              <p class="tagp">超低延迟</p>
            </div>
            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (3).png"
                alt="icon"
              />
              <p class="tagp">内容多重防盗</p>
            </div>
          </div>
        </van-swipe-item>

        <van-swipe-item class="myswiperitem">
          <div class="servetag">
            <img
              class="tagimg"
              src="https://oss.001ke.com/website-m/home/icon/3.png"
              alt="servetag"
            />
            <div class="sertaginfo">
              <p class="sertitle">闭环产品</p>
              <p class="sertitleafter">
                打通多渠道平台、深化知识场景、多营销互动玩法，赋能品牌转化和用户运营
              </p>
            </div>
          </div>

          <div class="serveinfo">
            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (7).png"
                alt="icon"
              />
              <p class="tagp">多形态知识产品</p>
            </div>

            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (4).png"
                alt="icon"
              />
              <p class="tagp">全渠道打通经营</p>
            </div>

            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (5).png"
                alt="icon"
              />
              <p class="tagp">数百种营销玩法</p>
            </div>
            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (6).png"
                alt="icon"
              />
              <p class="tagp">可视化数据分析</p>
            </div>
          </div>
        </van-swipe-item>

        <van-swipe-item class="myswiperitem">
          <div class="servetag">
            <img
              class="tagimg"
              src="https://oss.001ke.com/website-m/home/icon/3.png"
              alt="servetag"
            />
            <div class="sertaginfo">
              <p class="sertitle">急速服务</p>
              <p class="sertitleafter">
                365天全年无休，为您提供高效的沟通渠道，全过程顾问式服务，助力企业远航。
              </p>
            </div>
          </div>

          <div class="serveinfo">
            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (8).png"
                alt="icon"
              />
              <p class="tagp">7*24小时应对</p>
            </div>

            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (9).png"
                alt="icon"
              />
              <p class="tagp">专业运营指导</p>
            </div>

            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (10).png"
                alt="icon"
              />
              <p class="tagp">1v1售后支持</p>
            </div>
            <div class="item">
              <img
                class="icon"
                src="https://oss.001ke.com/website-m/home/icon/icon2 (11).png"
                alt="icon"
              />
              <p class="tagp">直播报备全程保障</p>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="btn">
      <router-link :to="{ name: 'platformbkserve' }">查看详情</router-link>
      
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem } from "vant";
export default {
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
};
</script>

<style lang="less" scoped>
.serveshow {
  width: 100%;
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  background: #000;
  margin-top: 20px;
  align-items: center;
  .title {
    width: 100%;
    background: linear-gradient(
        90deg,
        #ffef61 15%,
        #ff93e1 58.54%,
        #aa95ff 66.67%,
        #95dfff 90%
      ),
      #fff;
    font-size: 16px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
  }
  .titleafter {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.5);
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }
  .swipercontent {
    width: 80%;
    height: 160px;
    border: 1px solid #293241;
    background: rgba(28, 35, 46, 0.86);
    box-shadow: inset 0 -1px 1px rgba(172, 225, 255, 0.05),
      inset 0 1px 1px rgba(172, 225, 255, 0.06);
    border-radius: 8px;
    margin-top: 20px;
    position: relative;
    .myswipe {
      height: 100%;
      .myswiperitem {
        width: 80%;
        height: 100%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        .servetag {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-around;
          .tagimg {
            width: 54px;
            height: 54px;
          }
          .sertaginfo {
            width: calc(100vw * 0.51);
            .sertitle {
              font-size: 14px;
              color: #fff;
              margin-bottom: 4px;
            }
            .sertitleafter {
              font-size: 9px;
              color: rgba(255, 225, 255, 0.5);
            }
          }
        }

        .serveinfo {
          width: 90%;
          height: calc(100vh * 0.052);
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 4px;
            img {
              width: 9px;
              height: 9px;
              margin-right: 4px;
            }
            .tagp {
              font-size: 11px;
              color: #fff;
              width: 100px;
            }
          }
        }
      }
    }
  }

  .btn {
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    border: 1px solid #293241;
    color: #fff;
    border-radius: 12px;
    margin-top: 10px;
  }
}
</style>
