<template>
  <div class="productshow">
    <p class="title">产品展示</p>
    <div class="tagcontent">
      <div class="tag">
        <div class="tagitem" @click="getimg(1)">高效引流</div>
        <div class="tagitem" @click="getimg(2)">智能学习</div>
        <div class="tagitem" @click="getimg(3)">促销转换</div>
      </div>
      <div class="taginfo">
        <img class="taginfoimg" :src="taginfo1.img" alt="proimg" />
        <div class="theinfo">
          <div>
            <div class="infotitle">
              <img src="https://oss.001ke.com/website-m/home/icon/1.png" alt="icon" />
              <p class="commomp">易知课堂小程序</p>
            </div>
            <p class="showp">{{ taginfo1.showp }}</p>
            <p class="commomp">
              {{ taginfo1.commonp }}
            </p>
          </div>

          <div class="btn">
            <router-link :to="{ name: 'platform' }">查看详情</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="tagcontent">
      <div class="tag">
        <div class="tagitem" @click="getimgtwo(1)">交易系统</div>
        <div class="tagitem" @click="getimgtwo(2)">知识店铺</div>
        <div class="tagitem" @click="getimgtwo(3)">账号管理</div>
      </div>
      <div class="taginfo">
        <div class="theinfo">
          <div>
            <div class="infotitle">
              <img src="https://oss.001ke.com/website-m/home/icon/2.png" alt="icon" />
              <p class="commomp">易知课堂后台</p>
            </div>
            <p class="showp">{{ taginfo2.showp }}</p>
            <p class="commomp">{{ taginfo2.commonp }}</p>
          </div>

          <div class="btn">
            <router-link :to="{ name: 'platformbkserve' }"
              >查看详情</router-link
            >
          </div>
        </div>
        <img class="taginfoimg" :src="taginfo2.img" alt="proimg" />
      </div>
    </div>
  </div>
</template>

<script>
import { productshowdataone, productshowdatatwo } from "./data/productshow.js";
export default {
  data() {
    return {
      productshowdataone,
      productshowdatatwo,
      taginfo1: {},
      taginfo2: {},
    };
  },
  created() {
    this.taginfo1 = this.productshowdataone[0];
    this.taginfo2 = this.productshowdatatwo[0];
  },
  methods: {
    getimg(i) {
      this.taginfo1 = this.productshowdataone[i - 1];
    },
    getimgtwo(i) {
      this.taginfo2 = this.productshowdatatwo[i - 1];
    },
  },
};
</script>

<style lang="less" scoped>
.productshow {
  width: 100vw;
  margin-top: 20px;
  .title {
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin: 20px 0;
  }
  .tagcontent {
    padding: 10px;
    width: 100%;
    .tag {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .tagitem {
        margin-top: 5px;
        margin-bottom: 0px;
        width: 90px;
        height: 27px;
        color: rgb(73 80 87);
        background: rgb(243 246 255);
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
      }
    }
    .taginfo {
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      position: relative;
      .taginfoimg {
        width: 172px;
        height: 172px;
        object-fit: contain;
        margin-right: 12px;
        margin-top: 24px;
        border-radius: 18px;
      }
      .theinfo {
        width: 47%;
        color: rgb(73, 80, 87);
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        position: relative;
        top: -5px;
        .infotitle {
          display: flex;
          align-items: center;

          margin-bottom: 5px;
          .commomp {
            font-size: 12px;
          }
        }
        .showp {
          font-size: 15px;
          color: rgb(20, 72, 255);
          font-weight: 700;
          line-height: 24px;
        }
        .commomp {
          font-size: 12px;
          line-height: 17px;
        }
        .btn {
          width: 104px;
          height: 30px;
          background: #fa0;
          border-radius: 6px;
          color: #fff;
          font-size: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 12px;
        }
      }
    }
  }
}
</style>
