<template>
  <div>
    <headerer></headerer>
    <div class="dy">
      <img
        class="topbg"
        src="https://oss.001ke.com/website-m/platform/kuaishou/bg.png"
        alt=""
      />
      <p class="title">四大核心竞争力</p>
      <div class="platformtagbox">
        <div class="box">
          <img src="https://oss.001ke.com/website-m/platform/demo3.jpg" alt="" />
          <div class="info">
            <p class="title">知识付费变现</p>
            <p class="titleafter">
              根据内容创作者的实际情况，量身定制专属的内容变现方案，陪伴式扶持成长。
            </p>
          </div>
        </div>

        <div class="box">
          <img src="https://oss.001ke.com/website-m/platform/demo4.jpg" alt="" />
          <div class="info">
            <p class="title">好课分销生态链</p>
            <p class="titleafter">
              根据内容创作者的实际情况，量身定制专属的内容变现方案，陪伴式扶持成长。
            </p>
          </div>
        </div>
        <div class="box">
          <img src="https://oss.001ke.com/website-m/platform/demo2.jpg" alt="" />
          <div class="info">
            <p class="title">工具助力营销变现</p>
            <p class="titleafter">
              PC端为您提供红包、优惠券、限时抢购、套餐等丰富的营销⼯具，满⾜全场景营销诉求，助⼒提升付费转化。
            </p>
          </div>
        </div>
        <div class="box">
          <img src="https://oss.001ke.com/website-m/platform/demo.jpg" alt="" />
          <div class="info">
            <p class="title">智能交易系统分析</p>
            <p class="titleafter">
              根据内容创作者的实际情况，量身定制专属的内容变现方案，陪伴式扶持成长。
            </p>
          </div>
        </div>
      </div>

      <div class="imgbox">
        <img src="https://oss.001ke.com/website-m/platform/kuaishou/1 (1).png" alt="" />

        <p class="title3">定向流量精准扶持-人群包</p>
        <p class="title3after">
          鲸落课堂平台与快手达成深度合作，定期给予新老用户流量扶持，精准引流有效用户，帮助新用户快速冷启动，助力老用户持续增长
        </p>

        <img src="https://oss.001ke.com/website-m/platform/kuaishou/1 (4).png" alt="" />

        <p class="title3">快手专属定制化小程序</p>
        <p class="title3after">
          客户可依据喜好定制个人独属的知识付费小程序，连接公域流量与知识店铺，打造引流获客、私域交付的闭环
        </p>

        <img src="https://oss.001ke.com/website-m/platform/kuaishou/1 (3).png" alt="" />

        <p class="title3">客制化内容变现方案</p>
        <p class="title3after">
          客制化内容变现方案为客户解决获客成本高、转化效率低、留存复购差、客单提升难等私域营销问题，精准挖掘用户最大价值
        </p>

        <img src="https://oss.001ke.com/website-m/platform/kuaishou/1 (2).png" alt="" />

        <p class="title3">流量触达方式多样化</p>
        <p class="title3after" style="margin-bottom:100px">
          整合视频、直播、订单中心等多位置触达用户，支持配置个人主页，持续获客引流
        </p>
      </div>
    </div>
    <footerer></footerer>
  </div>
</template>

<script>
import headerer from "../headerer.vue";
import footerer from "../footerer.vue";
export default {
  components: {
    headerer,
    footerer,
  },
};
</script>

<style lang="less" scoped>
.dy {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: renderpage 1s cubic-bezier(0, 0.68, 1, 0.64) forwards;
  .platformtagbox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .box {
      width: 90%;
      height: 112px;
      background-color: #f9fbff;
      margin-bottom: 10px;
      display: flex;
      img {
        height: 110px;
        height: 110px;
        object-fit: contain;
      }
      .info {
        padding: 20px;
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #000;
          text-align: left;
          margin: 0;
          margin-bottom: 16px;
        }
        .titleafter {
          color: rgba(33, 36, 41, 0.5);
          font-size: 12px;
        }
      }
    }
  }
  .imgbox {
    padding: 10px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}
.topbg {
  width: 100%;
  object-fit: contain;
}
.title {
  font-weight: 600;
  margin: 20px 0;
  font-size: 16px;
  color: #000;
  text-align: center;
}

.title2 {
  font-weight: 600;
  font-size: 14px;
  margin: 20px 0;
  text-align: center;
}

.title3 {
  font-weight: 500;
  margin: 20px 0;
  font-size: 16px;
  color: #000;
  text-align: center;
}

.title3after {
  font-size: 12px;
  color: rgba(33, 36, 41, 0.5);
  text-align: center;
  width: 80%;
}

@keyframes renderpage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
