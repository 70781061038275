const productshowdataone = [
  {
    showp: "视频挂载&直播挂载，触达多样化",
    commonp: "用户点击后立即跳转至“易知课堂”小程序购买页直接购买。",
    img: "https://oss.001ke.com/website-m/home/1.png",
  },
  {
    showp: "内容形式丰富，学习数据实时追踪反馈",
    commonp: "含直播课、录播课、图文课等多种授课形式，学员学习结果有反馈。",
    img: "https://oss.001ke.com/website-m/home/2.png",
  },
  {
    showp: "丰富的营销工具，沉淀私域流量",
    commonp: "包括抖音群聊、粉丝圈、打卡/作业，助力达人实现高效转化。",
    img: "https://oss.001ke.com/website-m/home/3.png",
  },
];

const productshowdatatwo = [
  {
    showp: "多维度分析运营效果，数据可视化",
    commonp: "动态分析操作，层层剖析数据，深挖运营问题根源。",
    img: "https://oss.001ke.com/website-m/home/4.png",
  },
  {
    showp: "打造企业品牌独立小程序, 满足DIY",
    commonp: "满足达人多形式变现、运营、个性装修等服务。",
    img: "https://oss.001ke.com/website-m/home/5.png",
  },
  {
    showp: "多推广号实时售卖指标检测, 轻管理",
    commonp: "多推广号实时售卖指标检测, 轻管理。",
    img: "https://oss.001ke.com/website-m/home/6.png",
  },
];

export { productshowdataone, productshowdatatwo };
